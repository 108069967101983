import Swiper from "swiper";
import { Pagination, Autoplay, Navigation } from "swiper/modules";

let homeBannerSlider = new Swiper(".home-banner-slider .swiper", {
	modules: [Pagination, Autoplay],
	slidesPerView: 1,
	spaceBetween: 12,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
});

// const newsSlider = new LoopSlider(".news-slider", {
// 	576: {
// 		spaceBetween: 16,
// 		slidesPerView: 2.5,
// 	},
// 	768: {
// 		spaceBetween: 16,
// 		slidesPerView: 3.5,
// 	},
// 	1024: {
// 		spaceBetween: 32,
// 		slidesPerView: 4,
// 	},
// });
